// Vue components must be registered in PascalCase
// eg: <ComponentName></ComponentName>
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const debugMode = false;

// require.context gets all the files in ./vue folder
// it allows us to then async load those components later if they exist in the DOM
// https://webpack.js.org/api/module-methods/#requirecontext

const vueComponents = require.context(
	// The relative path of the components folder
	'./vue-apps/apps/',
	// Whether or not to look in subfolders
	true,
	// The regular expression used to match base component filenames
	/[A-Z]\w+\.(vue)$/,
	// The mode with which we load by
	'lazy'
)

// object of vue components in format [name]:path
const vueComponentsData = {}
vueComponents.keys().forEach(filePath => {
	// get PascalCase name of component
	const appName = upperFirst(
		camelCase(
			// strip the leading `./` and extension from the filename
			filePath
				.replace(/^.*[\\\/]/, '') // eslint-disable-line
				.split('.')
				.slice(0, -1)
				.join('.')
		)
	)
	vueComponentsData[appName] = filePath.replace(/^\.\//, './vue-apps/apps/')
})

// selector we use to check if vue is child of vue
const vueComponentsSelector = Object.keys(vueComponentsData).join(',')

// array of components visible in DOM
const vueApps = []

// loop components that are available
Object.keys(vueComponentsData).forEach(appName => {
	// get vue components in DOM
	let els = document.querySelectorAll(appName)
	if (els.length) {
		// remove slots from the list as they will be rendered within vue component itself
		// https://vuejs.org/v2/guide/components-slots.html
		els = [...els].filter(el => !el.parentNode.closest(vueComponentsSelector))

		vueApps.push({
			path: vueComponentsData[appName],
			name: appName.toLowerCase(),
			els: els
		})
	}
})

// check if we have vue components in DOM
if (vueApps.length) {
	// import vue dynamically
	import(/* webpackChunkName: "vue-boilerplate"*/ './vue-boilerplate').then(module => {
		// get vue, store and router instances from the boilerplate
		const Vue = module.default
		const store = module.store
		const router = module.router

		// globally define all the components
		vueApps.forEach(app => {
			Vue.component(app.name, () => import(`${app.path}`))
			Vue.config.productionTip = false;
			app.els.forEach(el => {
				new Vue({
					el,
					store,
					router
				})
			})
		})
	})
}

if(debugMode){
	console.log({vueComponents, vueComponentsData, vueComponentsSelector, vueApps})
}
