import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs'; // Querystring plugin

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',

  parseQuery(query) {
    return qs.parse(query);
  },
  stringifyQuery(query) {
    const result = qs.stringify(query, {encode: false});
    return result ? `?${result}` : '';
  }
});

export default router;
