import breakpoints from '@helpers/breakpoints'
import { helpers } from '@helpers/helpers'

const siteSearch = () => {

	let searchBtn = document.getElementById('search-trigger')
	if(!searchBtn) return

	// Initially create the search element
	if (window.matchMedia(`(min-width: ${breakpoints.lg})`).matches) {
		createSiteSearch(searchBtn)
	}

	// Initialise on window resize, only if not already initialised
	var resizingId = false
	window.addEventListener('resize', () => {
		clearTimeout(resizingId);
		resizingId = setTimeout(doneResizing, 200);
	});
	function doneResizing() {
		if (window.siteSearch) return
		if (window.innerWidth >= breakpoints.lg) createSiteSearch(searchBtn)
	};
	(window.requestAnimationFrame) ? window.requestAnimationFrame(doneResizing) : doneResizing();

};

const createSiteSearch = (trigger) => {
	// To prevent recreation
	window.siteSearch = true

	var relatedPanel = trigger.getAttribute('aria-controls'),
		searchPanel = document.getElementById(relatedPanel),
		menuBackground = document.getElementById('menu-background')

	trigger.addEventListener('keydown', function(event){
		if ((event.keyCode && event.keyCode == 13) || (event.key && event.key.toLowerCase() == 'enter')) {
			event.preventDefault()
			toggleSearch(trigger, searchPanel, 'enter')
		}
	})
	trigger.addEventListener('click', function(event){
		event.preventDefault()
		toggleSearch(trigger, searchPanel, 'click')
	})
	searchPanel.addEventListener('keydown', function(event){
		if ((event.keyCode && event.keyCode == 27) || (event.key && event.key.toLowerCase() == 'escape')) {
			closeSearch(trigger, searchPanel, 'escape', menuBackground)
		}
		const focusableElements = helpers.getFocusableElements(searchPanel)
		if ((event.keyCode && event.keyCode == 9) || (event.key && event.key.toLowerCase() == 'tab')) {
			var last = (focusableElements.length - 1)
			if(event.target === focusableElements[last]) {
				closeSearch(trigger, searchPanel, 'tab', menuBackground)
			}
		}
		if (event.shiftKey && (event.keyCode && event.keyCode == 9) || event.shiftKey && (event.key && event.key.toLowerCase() == 'tab')) {
			if(event.target === focusableElements[0]) {
				closeSearch(trigger, searchPanel, 'tab', menuBackground)
			}
		}

	})
	window.addEventListener('resize', () => {
		if (window.innerWidth < breakpoints.lg) closeSearch(trigger, searchPanel, 'click', menuBackground)
		else return
	});
};

const toggleSearch = (trigger, panel, eventType) => {
	var isOpen = panel.classList.contains('is-open'),
		menuBackground = document.getElementById('menu-background')
	if(isOpen){
		closeSearch(trigger, panel, eventType, menuBackground)
	}
	if(!isOpen){
		openSearch(trigger, panel, eventType, menuBackground)
	}
}

const openSearch = (trigger, panel, eventType, menuBackground) => {
	helpers.removeClass(menuBackground, 'hidden');
	trigger.setAttribute('aria-expanded', 'true');
	helpers.addClass(trigger, 'is-open')
	helpers.removeClass(panel, 'hidden')
	helpers.addClass(panel, 'is-open')
	panel.setAttribute('aria-hidden', 'false');
	var parent = trigger.parentElement
	helpers.addClass(parent, 'is-open')
	if (eventType === 'enter') {
		let toFocus = helpers.getFirstFocusable(panel)
		toFocus.focus()
	}
}

const closeSearch = (trigger, panel, eventType, menuBackground) => {
	helpers.addClass(menuBackground, 'hidden');
	trigger.setAttribute('aria-expanded', 'false');
	helpers.removeClass(trigger, 'is-open')
	helpers.addClass(panel, 'hidden')
	helpers.removeClass(panel, 'is-open')
	panel.setAttribute('aria-hidden', 'true');
	var parent = trigger.parentElement
	helpers.removeClass(parent, 'is-open')
	if (eventType === 'escape') {
		trigger.focus()
	}
}

export default siteSearch;
