// NO JS Fallbacks for css
var root = document.getElementsByTagName( 'html' )[0];
root.classList.add("js")

// Webpack public path
import './helpers/public-path'

// Reduce motion
import detectReducedMotion from './helpers/reduce-motion';
detectReducedMotion()

// Field labels
import fileInputLabels from './modules/forms/upload-labels';
fileInputLabels()

// Lazyload images / video
import './modules/lazyload';

// loads vue components async and inits
import './vue-dynamic';

import Vue from 'vue';
import Vuex from 'vuex';
import router from './vue-apps/router/router.js';
Vue.use(Vuex);

const safeParseJSON = (data, fallback) => {
  try {
    return JSON.parse(data);
  } catch (error) {
    console.error('Failed to parse JSON:', error, data);
    return fallback;
  }
};

// Vue Apps ----------//
// Dynamic Pagination vue app //
const paginationElement = document.getElementById('dynamic-pagination');

if (paginationElement) {
  const pageCount = paginationElement.getAttribute('data-page-count');
  const currentPage = paginationElement.getAttribute('data-current-page');
  const query = paginationElement.getAttribute('data-query');

  // Dynamically import the DynamicPagination component
  import('./vue-apps/apps/DynamicPagination.vue').then(({ default: DynamicPagination }) => {
    new Vue({
      render: h => h(DynamicPagination, {
        props: {
          pageCount,
          currentPage,
          query
        }
      })
    }).$mount('#dynamic-pagination');
  }).catch(error => {
    console.error('Failed to load DynamicPagination component:', error);
  });
}

// Course compare vue app //
const courseCompareElement = document.getElementById('course-compare');
if (courseCompareElement) {
  // Import the store and CourseCompare component inside the condition
  Promise.all([
    import('./vue-apps/store/course-compare/index.js'), // Dynamically import the store module
    import('vuex'), // Import Vuex
    import('./vue-apps/apps/CourseCompare.vue') // Dynamically import the CourseCompare component
  ]).then(([storeModule, Vuex, CourseCompareModule]) => {
    Vue.use(Vuex.default); // Use Vuex

    const store = new Vuex.default.Store({
      modules: {
        courseCompare: storeModule.default
      }
    });

    const CourseCompare = CourseCompareModule.default;

    const addCourseLabel = courseCompareElement.getAttribute('data-add-course-label');
    const coursePlaceholderLabel = courseCompareElement.getAttribute('data-course-placeholder-label');
    const clearLabel = courseCompareElement.getAttribute('data-clear-label');
    const compareLabel = courseCompareElement.getAttribute('data-compare-label');
    const closeLabel = courseCompareElement.getAttribute('data-close-label');
    const viewLabel = courseCompareElement.getAttribute('data-view-label');
    const removeCourseLabel = courseCompareElement.getAttribute('data-remove-course-label');
    const compareApiUrl = courseCompareElement.getAttribute('data-compare-api-url');

    new Vue({
      store, // Ensure the store is available to the Vue instance
      render: h => h(CourseCompare, {
        props: {
          addCourseLabel,
          coursePlaceholderLabel,
          clearLabel,
          compareLabel,
          closeLabel,
          viewLabel,
          removeCourseLabel,
          compareApiUrl
        }
      })
    }).$mount('#course-compare');
  }).catch(error => {
    console.error('Failed to load store and/or component:', error);
  });
}


// Book Course vue app //
const bookCourseElement = document.getElementById('book-course');

if (bookCourseElement) {
  // Import the store and BookCourse component inside the condition
  Promise.all([
    import('./vue-apps/store/book-course/index.js'), // Dynamically import the store module
    import('vuex'), // Import Vuex
    import('./vue-apps/apps/BookCourse.vue'), // Dynamically import the BookCourse component
    import('vue-router'), // Import Vue Router
    import('./vue-apps/router/router.js') // Import the router configuration
  ]).then(([storeModule, Vuex, BookCourseModule, VueRouter, routerInstance]) => {
    Vue.use(Vuex.default); // Use Vuex
    Vue.use(VueRouter.default); // Use Vue Router

    const store = new Vuex.default.Store({
      modules: {
        bookCourse: storeModule.default
      }
    });

    const router = routerInstance.default;

    const BookCourse = BookCourseModule.default;

    const industryOptionsData = bookCourseElement.getAttribute('data-industry-options');
    const organisationSizeOptionsData = bookCourseElement.getAttribute('data-organisation-size-options');
    const hearAboutUsOptionsData = bookCourseElement.getAttribute('data-hear-about-us-options');

    const props = {
      debug: bookCourseElement.getAttribute('data-debug'),
      bookClass: bookCourseElement.getAttribute('data-class'),
      productId: bookCourseElement.getAttribute('data-product-id'),
      courseApiUrl: bookCourseElement.getAttribute('data-course-api-url'),
      bookingSubmitApiUrl: bookCourseElement.getAttribute('data-booking-submit-api-url'),
      industryOptions: safeParseJSON(industryOptionsData, []),
      organisationSizeOptions: safeParseJSON(organisationSizeOptionsData, []),
      showPaymentInfo: bookCourseElement.getAttribute('data-show-payment-info'),
      onlineCourseInfoBox: bookCourseElement.getAttribute('data-online-course-info-box'),
      bookingForOrganisationTitle: bookCourseElement.getAttribute('data-booking-for-organisation-title'),
      attendeeDetailsTitle: bookCourseElement.getAttribute('data-attendee-details-title'),
      mobileNumberHelperText: bookCourseElement.getAttribute('data-mobile-number-helper-text'),
      addressHelperText: bookCourseElement.getAttribute('data-address-helper-text'),
      paymentResponsabilityTitle: bookCourseElement.getAttribute('data-payment-responsability-title'),
      paymentResponsabilityText: bookCourseElement.getAttribute('data-payment-responsability-text'),
      paymentInfoTitle: bookCourseElement.getAttribute('data-payment-info-title'),
      addAttendeeButtonText: bookCourseElement.getAttribute('data-add-attendee-button-text'),
      totalPriceTitle: bookCourseElement.getAttribute('data-total-price-title'),
      bookButtonText: bookCourseElement.getAttribute('data-book-button-text'),
      removeAttendeeTitle: bookCourseElement.getAttribute('data-remove-attendee-title'),
      removeAttendeeText: bookCourseElement.getAttribute('data-remove-attendee-text'),
      removeAttendeeConfirm: bookCourseElement.getAttribute('data-remove-attendee-confirm'),
      removeAttendeeDecline: bookCourseElement.getAttribute('data-remove-attendee-decline'),
      privacyText: bookCourseElement.getAttribute('data-privacy-text'),
      hearAboutUsOptions: safeParseJSON(hearAboutUsOptionsData, []),
      paymentContent: bookCourseElement.getAttribute('data-payment-content'),
      apiErrorText: bookCourseElement.getAttribute('data-api-error-text'),
      availabilityFetchErrorText: bookCourseElement.getAttribute('data-availability-fetch-error-text'),
      maxAttendeesText: bookCourseElement.getAttribute('data-max-attendees-text'),
    };

    new Vue({
      el: '#book-course',
      store,
      router,
      render: (h) => h(BookCourse, { props }),
    });
  });
}

// Course listing vue app //

const courseListingElement = document.getElementById('course-listing');

if (courseListingElement) {
  const courseClass = courseListingElement.getAttribute('data-class');
  const courseItemsUrl = courseListingElement.getAttribute('data-course-items-url');
  const instructorItemsUrl = courseListingElement.getAttribute('data-instructor-items-url');
  const parentId = courseListingElement.getAttribute('data-parent-id');
  const showTopBar = courseListingElement.getAttribute('data-show-top-bar');
  const instructorId = courseListingElement.getAttribute('data-instructor-id');
  const filterText = courseListingElement.getAttribute('data-filter-text');
  const filterViewText = courseListingElement.getAttribute('data-filter-view-text');
  const filterCloseText = courseListingElement.getAttribute('data-filter-close-text');
  const filterClearText = courseListingElement.getAttribute('data-filter-clear-text');
  const mobileFilterText = courseListingElement.getAttribute('data-mobile-filter-text');
  const filterApplyText = courseListingElement.getAttribute('data-filter-apply-text');
  const searchLabel = courseListingElement.getAttribute('data-search-label');
  const locationSearchLabel = courseListingElement.getAttribute('data-location-search-label');
  const locationSearchPlaceholder = courseListingElement.getAttribute('data-location-search-placeholder');
  const instructorSearchLabel = courseListingElement.getAttribute('data-instructor-search-label');
  const instructorSearchPlaceholder = courseListingElement.getAttribute('data-instructor-search-placeholder');
  const instructorSearchNoResults = courseListingElement.getAttribute('data-instructor-search-no-results');
  const dateFromLabel = courseListingElement.getAttribute('data-date-from-label');
  const dateFromPlaceholder = courseListingElement.getAttribute('data-date-from-placeholder');
  const dateToLabel = courseListingElement.getAttribute('data-date-to-label');
  const dateToPlaceholder = courseListingElement.getAttribute('data-date-to-placeholder');
  const attendeesLabel = courseListingElement.getAttribute('data-attendees-label');
  const attendeesPlaceholder = courseListingElement.getAttribute('data-attendees-placeholder');
  const searchButtonLabel = courseListingElement.getAttribute('data-search-button-label');
  const noResultsTitle = courseListingElement.getAttribute('data-no-results-title');
  const noResultsText = courseListingElement.getAttribute('data-no-results-text');
  const errorText = courseListingElement.getAttribute('data-error-text');

  // Import dependencies inside the condition
  Promise.all([
    import('./vue-apps/store/courses/index.js'),
    import('vuex'),
    import('./vue-apps/apps/CourseListing.vue'),
    import('vue-router'),
    import('./vue-apps/router/router.js')
  ]).then(([storeModule, Vuex, CourseListingModule, VueRouter, routerInstance]) => {
    Vue.use(Vuex.default);
    Vue.use(VueRouter.default);

    const store = new Vuex.default.Store({
      modules: {
          courses: storeModule.default  // Correct module registration
      }
  });


  const router = routerInstance.default;
  const CourseListing = CourseListingModule.default;

    const props = {
      class: courseClass,
      courseItemsUrl,
      instructorItemsUrl,
      parentId,
      showTopBar,
      instructorId,
      filterText,
      filterViewText,
      filterCloseText,
      filterClearText,
      mobileFilterText,
      filterApplyText,
      searchLabel,
      locationSearchLabel,
      locationSearchPlaceholder,
      instructorSearchLabel,
      instructorSearchPlaceholder,
      instructorSearchNoResults,
      dateFromLabel,
      dateFromPlaceholder,
      dateToLabel,
      dateToPlaceholder,
      attendeesLabel,
      attendeesPlaceholder,
      searchButtonLabel,
      noResultsTitle,
      noResultsText,
      errorText
    };

    new Vue({
      el: '#course-listing',
      store,
      router,
      render: h => h(CourseListing, { props })
    });
  }).catch(error => {
    console.error('Failed to load dependencies:', error);
  });
}

// Resource listing vue app //
const resourceListingElement = document.getElementById('resource-listing');

if (resourceListingElement) {
   // Dynamically import dependencies
   Promise.all([
    import('./vue-apps/apps/ResourceListing.vue'),  // Import the ResourceListing component
    import('vuex'),  // Import Vuex
    import('vue-router'),  // Import Vue Router
    import('./vue-apps/router/router.js'),  // Import the router configuration
    import('./vue-apps/store/resources/index.js')  // Import the store module
  ]).then(([ResourceListingModule, Vuex, VueRouter, routerInstance, storeModule]) => {
    Vue.use(Vuex.default);  // Use Vuex
    Vue.use(VueRouter.default);  // Use Vue Router

    const store = new Vuex.default.Store({
      modules: {
        resources: storeModule.default
      }
    });

    const router = routerInstance.default;

    const ResourceListing = ResourceListingModule.default;

    const props = {
      class: resourceListingElement.getAttribute('data-class'),
      url: resourceListingElement.getAttribute('data-url'),
      urlSortOrder: resourceListingElement.getAttribute('data-url-sort-order'),
      allowGatedContent: resourceListingElement.getAttribute('data-allow-gated-content'),
      parentId: resourceListingElement.getAttribute('data-parent-id'),
      resourcePageId: resourceListingElement.getAttribute('data-resource-page-id'),
      showingText: resourceListingElement.getAttribute('data-showing-text'),
      typeText: resourceListingElement.getAttribute('data-type-text'),
      filterText: resourceListingElement.getAttribute('data-filter-text'),
      filterViewText: resourceListingElement.getAttribute('data-filter-view-text'),
      filterCloseText: resourceListingElement.getAttribute('data-filter-close-text'),
      filterClearText: resourceListingElement.getAttribute('data-filter-clear-text'),
      mobileFilterText: resourceListingElement.getAttribute('data-mobile-filter-text'),
      filterApplyText: resourceListingElement.getAttribute('data-filter-apply-text'),
      errorText: resourceListingElement.getAttribute('data-error-text'),
      noResultsTitle: resourceListingElement.getAttribute('data-no-results-title'),
      noResultsText: resourceListingElement.getAttribute('data-no-results-text'),
      viewResourceLabel: resourceListingElement.getAttribute('data-view-resource-label'),
      downloadResourceLabel: resourceListingElement.getAttribute('data-download-resource-label'),
      unlockResourceLabel: resourceListingElement.getAttribute('data-unlock-resource-label'),
      newLabel: resourceListingElement.getAttribute('data-new-label'),
      defaultSortOrder: resourceListingElement.getAttribute('data-default-sort-order')
    };

    new Vue({
      el: '#resource-listing',
      store,
      router,
      render: h => h(ResourceListing, { props })
    });
  }).catch(error => {
    console.error('Failed to load ResourceListing component:', error);
  });
}





//--------------------//




// Primary Navigation
if (document.getElementById('primary-nav')) {
	import(/* webpackChunkName: "primaryNav" */ './modules/primary-nav').then(primaryNav =>
		primaryNav.default()
	);
}

// Campaign Banner
if (document.getElementById('campaign-banner')) {
	import(/* webpackChunkName: "campaignBanner" */ './modules/campaign-banner').then(campaignBanner =>
		campaignBanner.default()
	);
}


// Site Search
import siteSearch from './modules/site-search';
siteSearch();

import mobileMenu from './modules/mobile-nav';
mobileMenu();

// Scroll top button
import scrollTop from './modules/scroll-top';
scrollTop();

// Animations
import observeAnimation from './modules/animation';
observeAnimation();

// Animated underline
if (document.querySelector('.js-animated-underline')) {
	let elementList = document.getElementsByClassName('js-animated-underline');
	import(/* webpackChunkName: "animatedUnderline" */ './modules/animated-underline').then(initAnimatedUnderlines =>
		initAnimatedUnderlines.default(elementList)
	);
}

// Calculate article time to read
if (document.querySelector('.js-calculate-time-to-read')) {
	let theArticle = document.querySelector('.js-calculate-time-to-read');
	import(/* webpackChunkName: "readingTime" */ './modules/reading-time').then(initReadingTime =>
		initReadingTime.default(theArticle)
	);
}

// Video embeds
if (document.querySelector('.js-video-embed')) {
	let elementList = document.getElementsByClassName('js-video-embed');
	import(/* webpackChunkName: "videoEmbed" */ './modules/video-embed').then(initVideoEmbed =>
		initVideoEmbed.default(elementList)
	);
}

// Accordions if needed
if (document.querySelector('.js-accordion-block')) {
	let accordionList = document.getElementsByClassName('js-accordion-block');
	import(/* webpackChunkName: "initAccordions" */ './modules/accordion').then(initAccordions =>
		initAccordions.default(accordionList)
	);
}

// Tabs if needed
if (document.querySelector('.js-tabs')) {
	let tabsList = document.getElementsByClassName('js-tabs');
	import(/* webpackChunkName: "initTabs" */ './modules/tabs').then(initTabs =>
		initTabs.default(tabsList)
	);
}

// Get in touch trigger
if (document.querySelector('.js-get-in-touch-trigger')) {
  import(/* webpackChunkName: "getInTouchForm" */ './modules/get-in-touch-form').then(getInTouchForm =>
    getInTouchForm.default()
  );
}

// If newsletter signup is needed
if (document.getElementById('newsletter-signup-form')) {
	import(/* webpackChunkName: "newsletterSignup" */ './modules/newsletter-signup').then(newsletterSignup =>
		newsletterSignup.default()
	);
  }

// If newsletter signup is needed
if (document.querySelector('.js-dynamics-form')) {
	import(/* webpackChunkName: "dynamicsForm" */ './modules/dynamics-form').then(dynamicsForm =>
		dynamicsForm.default()
	);
}

// Sliders
if (document.querySelector('.js-carousel-block')) {
  import(/* webpackChunkName: "carousel" */ './modules/carousel').then(carousel => carousel.default());
}
if (document.querySelector('.js-card-carousel-block')) {
	import(/* webpackChunkName: "cardCarousel" */ './modules/card-carousel').then(cardCarousel => cardCarousel.default());
}
if (document.querySelector('.js-quote-carousel-block')) {
	import(/* webpackChunkName: "quoteCarousel" */ './modules/quote-carousel').then(quoteCarousel => quoteCarousel.default());
}
// Form Validation
if (document.querySelector('.js-form-validation')) {
	import(/* webpackChunkName: "formValidation" */ './modules/forms/form-validation').then(formValidation => formValidation.default());
}
// Workplace screening form
if (document.querySelector('.js-workplace-screening-form')) {
	import(/* webpackChunkName: "workplaceScreening" */ './modules/forms/workplace-screening').then(workplaceScreening => workplaceScreening.default());
}
// course screening form
if (document.querySelector('.js-course-screening-form')) {
	import(/* webpackChunkName: "workplaceScreening" */ './modules/forms/course-screening').then(courseScreening => courseScreening.default());
}

// Responsive tables
if (document.querySelector('table:not([class])')) {
  import(/* webpackChunkName: "responsiveTable" */ './modules/responsive-table').then(responsiveTable =>
    responsiveTable.default()
  );
}

// Slider with an image gallery
if (document.querySelector('.js-image-gallery-block')) {
  import(/* webpackChunkName: "imageGallery" */ './modules/image-gallery-block').then(imageGalleryBlock =>
    imageGalleryBlock.default()
  );
}

// Slider with an thumb gallery
if (document.querySelector('.js-image-carousel-block')) {
	let inputList = document.getElementsByClassName('js-image-carousel-block');
	import(/* webpackChunkName: "imageCarouselWithThumbs" */ './modules/image-carousel-with-thumbs').then(imageCarouselBlock =>
	  imageCarouselBlock.default(inputList)
	);
  }

// Reviews slider
if (document.querySelector('.js-reviews-slider')) {
	import(/* webpackChunkName: "reviewsSlider" */ './modules/reviews-slider').then(reviewsSliderBlock =>
		reviewsSliderBlock.default()
	);
  }

// JS Date Pickers
if (document.querySelector('.js-date-input')) {
	let inputList = document.getElementsByClassName('js-date-input');
	import(/* webpackChunkName: "datepicker" */ './modules/datepicker').then(initDatePicker =>
		initDatePicker.default(inputList)
	);
}

// JS Additional form fields show/hide
if (document.querySelector('.js-optional-fields')) {
	let inputList = document.getElementsByClassName('js-optional-fields');
	import(/* webpackChunkName: "additionalFields" */ './modules/forms/additional-fields').then(initFormAdditionalFields =>
		initFormAdditionalFields.default(inputList)
	);
}


// Umbraco forms
if (document.querySelector('[class*="umbraco-form"]')) {
  import(/* webpackChunkName: "formSubmission" */ './modules/form-submission').then(formSubmission =>
    formSubmission.default()
  );
}

// Expanding hero text
if (document.querySelector('.js-expand-text')) {
	import(/* webpackChunkName: "expandText" */ './modules/expand-text').then(initExpandingText => initExpandingText.default());
}


if (document.querySelector('.grid-visual') && document.querySelector('.breakpoint') ) {
	const breakpointEl = document.querySelector('.breakpoint')
	const gridVisual = document.querySelector('.grid-visual')
	breakpointEl.addEventListener('click', function() {
		gridVisual.classList.toggle('hidden');
	})
}

// JS Date Pickers
if (document.getElementById('thankyouanimation')) {
	
	import(/* webpackChunkName: "datepicker" */ './modules/lottie/animation')
}


// WYSIWYG scripts
if (document.querySelector('.wysiwyg')) {
	import(/* webpackChunkName: "wysiwyg" */ './modules/wysiwyg').then(wysiwyg =>
		wysiwyg.default()
	);
}