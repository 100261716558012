import currentExecutingScript from 'current-executing-script';
// set the public path to be the path of the main entry script

__webpack_public_path__ = // eslint-disable-line

currentExecutingScript()
.src.split('?')[0]
.split('/')
.slice(0, -1)
.join('/') + '/';
